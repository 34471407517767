/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { gml } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Button from '../../../../components/Button';
import Steps from '../../../../components/alright/Steps';
import StepsList from '../../../../components/alright/StepsList';
import ModalAlert from '../../../../components/alright/ModalAlert';
import { useInstallations } from '../../../../contexts/InstallationsContext';

import stepsList from '../stepsList';
import * as S from './style';
import { PAGES } from '../../../../constants';
import Toast from '../../../../components/Toast';
import ComponentLoader from '../../../../components/alright/ComponentLoader';
import ErrorMessage from '../../ErrorMessage';

import { postEmailInstallationsDone } from '../../../../api/installations.service';
import { useGlobal } from '../../../../contexts/GlobalContext';

export default function Activate() {
  const [modalStatus, setModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const {
    adsTxt,
    publisherData,
    manualSteps,
    setManualSteps,
    getPublisher,
  } = useInstallations();
  const { publisher, setRequestError } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    setManualSteps(manualSteps, 0, 'success');
    setManualSteps(manualSteps, 1, 'current');
  }, [setManualSteps, manualSteps]);

  const downloadAds = () => {
    const element = document.createElement('a');
    const file = new Blob([adsTxt], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'ads.txt';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const openModalStatus = () => {
    setModalStatus(true);
  };

  const closeModalStatus = () => {
    setModalStatus(false);
  };

  const activateError = () => {
    setManualSteps(manualSteps, 1, 'error');
    closeModalStatus();
  };

  const confirmModal = () => {
    if (publisherData.ads_txt) {
      navigate(PAGES.INSTALACOES.MANUAL_FORMULARIO());
    }
    activateError();
  };

  const changeStep = async () => {
    setLoading(true);

    try {
      const response = await getPublisher();

      if (response) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status) {
        setRequestError();
      }
    }

    openModalStatus();
  };

  const onCopyTag = () => {
    setToast(true);
  };

  const postFinalStepEmail = useCallback(async () => {
    try {
      await postEmailInstallationsDone(publisher.id);
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    }
  }, [publisher.id, setRequestError]);

  useEffect(() => {
    if (publisherData?.supertag && publisherData?.ads_txt) {
      postFinalStepEmail();
    }
  }, [postFinalStepEmail, publisherData]);

  return (
    <>
      <Steps list={manualSteps} />
      {!publisherData.ads_txt
      && (
        <ErrorMessage
          title="Ops, não encontramos o seu arquivo ads.txt :( "
          description="Repita esse passo para adicionar o arquivo ao seu site"
        />
      )}
      <S.Disclaimer>
        O ads.txt é um documento que contém códigos de autorização de compra e
        venda de espaços publicitários do seu site.
      </S.Disclaimer>
      {
        toast && (
          <Toast title="Ads.txt copiado! " color="success" />
        )
      }
      <StepsList list={stepsList[1]} />
      <CopyToClipboard
        text={publisherData.embed_code}
        onCopy={onCopyTag}
      >
        <S.Code style={gml}>
          {adsTxt}
        </S.Code>
      </CopyToClipboard>
      <S.ButtonLine>
        <S.DownloadLink onClick={downloadAds}>
          Fazer download do arquivo txt
        </S.DownloadLink>
        <Button
          color="primary"
          onClick={changeStep}
          outline
        >
          { loading
            ? <ComponentLoader />
            : <span>Confirmar instalação</span>}
        </Button>
      </S.ButtonLine>
      <ModalAlert
        title={publisherData.ads_txt ? 'Instalação confirmada' : 'Falha na instalação do ads.txt'}
        buttonText="Ok"
        onClose={closeModalStatus}
        onConfirm={confirmModal}
        type={publisherData.ads_txt ? 'success' : 'warning'}
        showModal={modalStatus}
      />
    </>
  );
}
