import React from 'react';
import 'sanitize.css';
import { createGlobalStyle } from 'styled-components';
import colors from '../../components/GlobalStyle/colors';

import '../../assets/vendor/nucleo/css/nucleo.css';
import '../../assets/scss/argon-dashboard-pro-react.scss';
import '../../assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';

const Global = createGlobalStyle`
  body {
    background-color: ${colors.background};
    font-family: 'Roboto', sans-serif;
  }

  a {
    color: ${colors.purple} !important;
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      color: ${colors.textBlack};
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    border: none;
    border-radius: 10.5;
    background: ${colors.lightGrey};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10.5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.heavyGrey};
    border-radius: 10.5px;
  }
`;

export default function GlobalStyle() {
  return (
    <Global />
  );
}
