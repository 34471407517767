import React from 'react';
import {
  UncontrolledAlert as ReactstrapUncontrolledAlert,
} from 'reactstrap';
import { string } from 'prop-types';

export default function Toast({
  title = '',
  color = 'primary',
  text = '',
  ...props
}) {
  return (
    <ReactstrapUncontrolledAlert color={color} {...props}>
      <span className="alert-icon">
        <i className="ni ni-like-2" />
      </span>
      <span className="alert-text ml-1">
        <strong>{ title }</strong>
        { text }
      </span>
    </ReactstrapUncontrolledAlert>
  );
}

Toast.propTypes = {
  title: string,
  color: string,
  text: string,
};
