/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useNavigate } from '@reach/router';

import Button from '../../../../components/Button';
import Steps from '../../../../components/alright/Steps';
import StepsList from '../../../../components/alright/StepsList';
import { useInstallations } from '../../../../contexts/InstallationsContext';

import stepsList from '../stepsList';
import * as S from './style';
import { PAGES } from '../../../../constants';

export default function Install() {
  const { wordpressSteps, setWordpressSteps } = useInstallations();
  const navigate = useNavigate();

  useEffect(() => {
    setWordpressSteps(wordpressSteps, 0, 'current');
  }, [setWordpressSteps, wordpressSteps]);

  const changeStep = () => {
    navigate(PAGES.INSTALACOES.WORDPRESS_ATIVAR());
  };

  return (
    <>
      <Steps list={wordpressSteps} />
      <S.Disclaimer>
        Alright Connector é um plugin que instala as ferramentas necessárias
        para que possamos incluir as publicidades no seu site. Não se preocupe, isso não reduz a velocidade dele, apenas faremos
        as instalações necessárias de modo prático.
      </S.Disclaimer>
      <StepsList list={stepsList[0]} />
      <S.ButtonLine>
        <Button
          color="primary"
          onClick={changeStep}
          outline
        >
          Próxima
        </Button>
      </S.ButtonLine>
    </>
  );
}
