import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Button as ReactstrapButton } from 'reactstrap';
import {
  node,
  bool,
  string,
  func,
} from 'prop-types';
import colors from '../../components/GlobalStyle/colors';

const ButtonComponent = styled(ReactstrapButton)`
  font-size: 14px;
  font-weight: 500;
  border-width: 2px;
  border-radius: 50px;

  &:hover {
    ${(props) => props.color === 'default'
      && css`
        background-color: ${colors.black};
        border-color: ${colors.black};
    `};

    ${(props) => props.color === 'primary'
      && css`
        background-color: ${colors.purple};
        border-color: ${colors.purple};
    `};

    ${(props) => props.disabled
      && css`
        background-color: ${colors.disabled};
        border-color: ${colors.disabled};
        color: ${colors.white} !important;
    `};
  }

  ${(props) => props.color === 'default'
    && css`
      border-color: ${colors.black};
  `};

  ${(props) => props.iswide
    && css`
      padding: 8px 26px;
  `};

  ${(props) => props.color === 'default' && !props.outline
    && css`
      background-color: ${colors.black};
  `};

  ${(props) => props.color === 'default' && props.outline
    && css`
      color: ${colors.black};
  `};

  ${(props) => props.color === 'primary'
    && css`
      border-color: ${colors.purple};
  `};

  ${(props) => props.color === 'primary' && !props.outline
    && css`
      background-color: ${colors.purple};
  `};

  ${(props) => props.color === 'primary' && props.outline
    && css`
      color: ${colors.purple};
  `};

  ${(props) => props.disabled
    && css`
      border-color: ${colors.disabled};
  `};

  ${(props) => props.disabled && !props.outline
    && css`
      background-color: ${colors.disabled};
  `};

  ${(props) => props.disabled && props.outline
    && css`
      color: ${colors.disabled} !important;
  `};
`;

export default function Button({
  children,
  icon = false,
  color = 'default',
  onClick = () => {},
  ...props
}) {
  return (
    <ButtonComponent
      type="button"
      color={color}
      className={icon ? 'btn-icon' : ''}
      onClick={onClick}
      {...props}
    >
      {children}
    </ButtonComponent>
  );
}

Button.propTypes = {
  icon: bool,
  color: string,
  onClick: func,
  children: node,
};
