const colors = {
  background: '#fafafa',
  black: '#000',
  nightRider: '#303030',
  heavyGrey: '#C0BFC1',
  lightGrey: '#D8D8D8',
  orange: '#fd9a6f',
  green: '#71f7b2',
  red: '#ff335d',
  purple: '#6244e8',
  flatPurple: '#a540fe',
  youngNight: '#232323',
  textBlack: '#333333',
  white: '#fff',
  disabled: '#aaaaaa',
};

export default colors;
