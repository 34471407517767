import React from 'react';
import { string } from 'prop-types';
import { Helmet } from 'react-helmet';

export default function Head({ title, description, ...props }) {
  return (
    <Helmet {...props}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    </Helmet>
  );
}

Head.defaultProps = {
  title: '',
  description: '',
};

Head.propTypes = {
  title: string,
  description: string,
  ...Helmet.propTypes,
};
