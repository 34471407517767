import React from 'react';
import { node } from 'prop-types';

export default function Container({ children }) {
  return (
    <>
      {children}
    </>
  );
}

Container.propTypes = {
  children: node,
};
