import styled, { css } from 'styled-components/macro';
import { Link } from '@reach/router';

import colors from '../../../../components/GlobalStyle/colors';
import Button from '../../../../components/Button';

export const StepLink = styled(Link)`
  text-decoration: none;
  z-index: 1;
`;

export const StepItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
`;

export const Description = styled.span`
  color: ${colors.purple};
  font-size: 15px;
  flex: 1;
  line-height: 1.3;
  margin-top: 5px;
  text-align: center;
  width: 150px;

  ${(props) => props.status === 'pending'
    && css`
      color: ${colors.heavyGrey};
  `};

  ${(props) => props.status === 'error'
    && css`
      color: ${colors.red};
  `};
`;

export const ButtonNumber = styled(Button)`
  background-color: ${colors.white};
  font-size: 20px;
  height: 45px;
  width: 45px;

  &:not(:last-child) {
    margin-right: 0;
  }

  ${(props) => props.disabled
    && css`
      background-color: ${colors.white} !important;
      border-color: ${colors.disabled};
      opacity: 1 !important;

      &:hover {
        background-color: ${colors.disabled} !important;
      }
  `};

  ${(props) => props.status === 'current'
    && css`
      cursor: default !important;
  `};

  ${(props) => props.status === 'success'
    && css`
      background-color: ${colors.purple};
      color: ${colors.white}
  `};

  ${(props) => props.status === 'error'
    && css`
      background-color: ${colors.red};
      border-color: ${colors.red};
      color: ${colors.white};

      &:hover {
        background-color: ${colors.red};
        border-color: ${colors.red};
      }
  `};
`;
