/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useNavigate } from '@reach/router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { gml } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Toast from '../../../../components/Toast';
import Button from '../../../../components/Button';
import Steps from '../../../../components/alright/Steps';
import StepsList from '../../../../components/alright/StepsList';
import ModalAlert from '../../../../components/alright/ModalAlert';
import { useInstallations } from '../../../../contexts/InstallationsContext';

import * as S from './style';
import { PAGES } from '../../../../constants';
import stepsList from '../stepsList';
import { useGlobal } from '../../../../contexts/GlobalContext';
import ComponentLoader from '../../../../components/alright/ComponentLoader';
import ErrorMessage from '../../ErrorMessage';

export default function Activate() {
  const [modalStatus, setModalStatus] = useState(false);
  const {
    publisherData,
    wordpressSteps,
    setWordpressSteps,
    getPublisher,
  } = useInstallations();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const navigate = useNavigate();
  const { setRequestError } = useGlobal();

  useEffect(() => {
    setWordpressSteps(wordpressSteps, 0, 'success');
    setWordpressSteps(wordpressSteps, 1, 'current');
  }, [setWordpressSteps, wordpressSteps]);

  const openModalStatus = () => {
    setModalStatus(true);
  };

  const closeModalStatus = () => {
    setModalStatus(false);
  };

  const activateError = () => {
    setWordpressSteps(wordpressSteps, 1, 'error');
    closeModalStatus();
  };

  const confirmModal = () => {
    if (publisherData.supertag) {
      navigate(PAGES.INSTALACOES.WORDPRESS_FORMULARIO());
    }
    return activateError();
  };

  const changeStep = async () => {
    setLoading(true);

    try {
      const response = await getPublisher();

      if (response) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status) {
        setRequestError();
      }
    }

    openModalStatus();
  };

  const onCopyCodeToast = () => {
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  return (
    <>
      <Steps list={wordpressSteps} />
      { wordpressSteps[1].status === 'error' && (
        <ErrorMessage
          title="Ops! Algo deu errado na ativação. :( "
          description="Certifique-se que esteja inserindo o código no local correto."
        />
      )}
      <S.Disclaimer>
        A Tag Alright é um código que nos permite instalar nossas tecnologias de inteligência de análise no seu site para que você acompanhe diariamente seus resultados.
      </S.Disclaimer>
      <StepsList list={stepsList[1]} />
      { toast && (
        <Toast title="Código copiado com sucesso! " color="success" />
      )}
      <S.SupertagTitle>
        <span>
          Copie sua
          <b>
            Tag Alright ID
          </b>
        </span>
      </S.SupertagTitle>
      { Object.entries(publisherData).length !== 0 && (
        <CopyToClipboard
          text={publisherData.supertag_id}
          onCopy={onCopyCodeToast}
        >
          <S.Code style={gml}>
            {publisherData.supertag_id}
          </S.Code>
        </CopyToClipboard>
      )}
      <S.ButtonLine>
        <Button
          color="primary"
          onClick={changeStep}
          outline
        >
          { loading
            ? <ComponentLoader />
            : <span>Confirmar instalação</span>}
        </Button>
      </S.ButtonLine>
      <ModalAlert
        title={publisherData.supertag ? 'Instalação confirmada' : 'Falha na instalação da Tag Alright'}
        buttonText="Ok"
        onClose={closeModalStatus}
        onConfirm={confirmModal}
        type={publisherData.supertag ? 'success' : 'warning'}
        showModal={modalStatus}
      />
    </>
  );
}
