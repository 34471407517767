/* eslint-disable quotes */

export const stepsList = {
  video: `Copie o código do formato VÍDEO clicando no botão "<strong>Video</strong>" e posicione o bloco de anúncio entre o segundo e terceiro parágrafo no seu site`,
  display: `Para posicionar o formato DISPLAY clique no botão "<strong>Display</strong>" e posicione no local solicitado. Posicionar o bloco de anuncio formato display na página, caso tenha mais de uma posição precisa numerar, ex: display_alright1, display_alright2`,
  comum: `Copie o código do formato Blocos de Anuncio Comum clicando no botão "<strong>Blocos-Comum</strong>" e posicione <!-- Body - ONDE DEVE CARREGAR O ANUNCIO--> no seu site.`,
  fixo: `Copie o código do formato BANNER FIXO clicando no botão "<strong>Banner-Script</strong>" e posicione em <strong>&lt; !--Head--&gt;</strong>
  e copie o código"<strong>Banner-Fixo</strong>" e posicione em "<strong>&lt !-- Body -- ONDE DEVE CARREGAR O ANUNCIO&gt;</strong>".`,
  flying:`Copie o código do formato FLYING CARPET clicando no botão "<strong>Flying-Script</strong>" e posicione em <strong>&lt; !--Head--&gt;</strong>
  e copie o código"<strong>Flying-Carpet</strong>" e posicione em "<strong>&lt !-- Body -- ONDE DEVE CARREGAR O ANUNCIO&gt;</strong>".`,
}
export const blocksTags = {
  tag_video: '<div id="video_alright"></div>',
  tag_display: '<div id="display_alright"></div>',
  tag_native: '<div id="native_alright"></div>',
  Blocos_Comum: '<amp-ad width=300 height=250 type="doubleclick" data-slot="/21830119956,publisherID/publiser.com/AMP" data-multi-size-validation="false"></amp-ad>',
  Banner_Script:'<script async custom-element="amp-sticky-ad" src="https://cdn.ampproject.org/v0/amp-sticky-ad-1.0.js"></script>',
  Banner_fixo:'<amp-sticky-ad layout="nodisplay"><amp-ad width="320" height="100" type="doubleclick" data-slot="/21830119956,publisherID/publiser.com/AMP"></amp-ad></amp-sticky-ad>',
  Flying_script:'<script async custom-element="amp-fx-flying-carpet" src="https://cdn.ampproject.org/v0/amp-fx-flying-carpet-0.1.js"></script>',
  Flying_Carpet:'<amp-sticky-ad layout="nodisplay"><amp-ad width="320" height="100" type="doubleclick" data-slot="/21830119956,publisherID/publiser.com/AMP"></amp-ad></amp-sticky-ad>',
}
  
    

