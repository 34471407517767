export const httpStatus = {
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  MOVED_PERMANENTLY: 301,
  NOT_FOUND: 404,
  NO_CONTENT: 204,
  OK: 200,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
};
