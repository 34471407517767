import styled from 'styled-components/macro';
import { Input as ReactstrapInput } from 'reactstrap';

import colors from '../../components/GlobalStyle/colors.js';

export const Input = styled(ReactstrapInput)`
  &:focus {
    border-color: ${colors.purple};
  }

  &.is-invalid {
    border-color: ${colors.red};
  }
`;
