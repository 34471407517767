import React from 'react';
import { string, node } from 'prop-types';
import { Badge as ReactStrapBadge } from 'reactstrap';

export default function Badge({
  color = 'default',
  type = '',
  size = 'md',
  children,
  ...props
}) {
  return (
    <ReactStrapBadge color={color} className={`badge-${size} badge-${type} badge-floating border-white`} {...props}>
      {children}
    </ReactStrapBadge>
  );
}

Badge.propTypes = {
  color: string,
  size: string,
  children: node,
  type: string,
};
