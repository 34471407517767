/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
export const empty = (data) => {
  if (typeof data === 'number' || typeof data === 'boolean') {
    return false;
  }

  if (typeof data === 'undefined' || data === null) {
    return true;
  }

  if (typeof data.length !== 'undefined') {
    return data.length === 0;
  }

  let count = 0;
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      count++;
    }
  }

  return count === 0;
};

export const isEmptyObject = (objeto) => {
  if (!objeto) {
    return true;
  }

  for (const field in objeto) {
    if (objeto.hasOwnProperty(field)) {
      if (!empty(objeto[field])) {
        return false;
      }
    }
  }

  return true;
};

export const hasUser = () => window.localStorage.getItem('usuario') !== null;
export const hasPublisher = () => window.localStorage.getItem('publisher') !== null;
