import React from 'react';
import {
  arrayOf,
  node,
  number,
  shape,
} from 'prop-types';

import StepLine from './StepLine';
import * as S from './style';

export default function StepsList({
  list,
  ...props
}) {
  return (
    <S.StepsContainer
      {...props}
    >
      {list.map((item) => (
        <StepLine
          key={item.id}
          description={item.description}
          itemIndex={item.id}
        />
      ))}
    </S.StepsContainer>
  );
}

StepsList.propTypes = {
  list: arrayOf(shape({
    description: node,
    id: number,
  })),
};
