import React, { useCallback } from 'react';
import { httpStatus } from '../utils/request/httpStatus';
import { getCardsStatus} from '../api/welcome.service';
import { hasUser, hasPublisher } from '../utils/helpers/helpers';

const GlobalContext = React.createContext();

function GlobalProvider(props) {
  const [state, setState] = React.useState({
    isUserLogged: false,
    usuario: undefined,
    publisher: undefined,
    stepsStatus: undefined,
    permissionError: false,
    isTermsConclusion: false,
    isLoading: true,
  });

  const value = React.useMemo(() => ({
    setState,
    state,
  }), [state]);

  return (
    <GlobalContext.Provider
      value={value}
      {...props}
    />
  );
}

function useGlobal() {
  const context = React.useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobal deve ser usado dentro de um GlobalProvider');
  }

  const { state, setState } = context;

  const setPermissionError = (status) => {
    setState((prevState) => ({
      ...prevState,
      permissionError: status,
    }));
  };

  const setIsUserLogged = (status) => {
    setState((prevState) => ({
      ...prevState,
      isUserLogged: status,
    }));
  };

  const setIsLoading = useCallback((status) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: status,
    }));
  }, [setState]);

  const setGlobalUserData = (data) => {
    const { senha, ...user } = data.usuario;

    setState((prevState) => ({
      ...prevState,
      usuario: user,
      publisher: data.publisher,
      isTermsConclusion: true,
    }));
    setIsUserLogged(true);

    if (hasUser() && hasPublisher()) {
      window.localStorage.setItem('publisher', JSON.stringify(data.publisher));
      window.localStorage.setItem('usuario', JSON.stringify(user));
    }
  };

  const setRequestError = useCallback((requestError) => {
    let error;

    if (requestError >= httpStatus.INTERNAL_SERVER_ERROR) {
      error = httpStatus.INTERNAL_SERVER_ERROR;
    } else if (requestError === httpStatus.NOT_FOUND) {
      error = httpStatus.NOT_FOUND;
    } else if (requestError === httpStatus.BAD_REQUEST) {
      error = httpStatus.BAD_REQUEST;
    }

    setState((prevState) => ({
      ...prevState,
      requestError: error,
    }));
  }, [setState]);

  const getUserProcessStatus = useCallback(async (publisherId) => {
    const token = window.localStorage.getItem('token');

    if (token && publisherId !== null) {
      try {
        const response = await getCardsStatus(token, publisherId);

        if (response) {
          setState((prevState) => ({
            ...prevState,
            stepsStatus: response,
            isUserLogged: true,
          }));

          setIsLoading(false);
        }
      } catch (error) {
        if (error?.response?.status) {
          setRequestError();
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [setIsLoading, setState, setRequestError]);

  const getPublisherData = useCallback(async () => {
    try {
      window.localStorage.getItem('token')
      const response = await getPublisherData();
      // const token = window.localStorage.getItem('token');

      if (response) {
        setState((prevState) => ({
          ...prevState,
          publisher: {
            ...prevState.publisher,
            id: response.publisher.id,
          },
          usuario: {
            ...prevState.usuario,
            id: response.usuario.id,
            nome: response.usuario.nome,
          },
        }));

        getUserProcessStatus(response.publisher.id);
        return;
      }
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    } finally {
      setIsLoading(false);
    }
  }, [getUserProcessStatus, setIsLoading, setRequestError, setState]);

  return {
    state,
    setRequestError,
    setIsUserLogged,
    getPublisherData,
    setGlobalUserData,
    setPermissionError,
    getUserProcessStatus,
    isLoading: state.isLoading,
    usuario: state.usuario,
    publisher: state.publisher,
    stepsStatus: state.stepsStatus,
    isUserLogged: state.isUserLogged,
    permissionError: state.permissionError,
  };
}

export { GlobalProvider, useGlobal };
