/* eslint-disable quotes */

const stepsListMock = [
  [
    {
      id: 1,
      description: `<a href="https://tagmanager.alright.network/wp-alright-plugin.zip">Clique aqui para fazer download do plugin</a>, mas <strong>não descompacte o arquivo .zip</strong>.`,
    },
    {
      id: 2,
      description: `Faça o login no seu painel do WordPress. Clique em "<strong>Plugins</strong>" no menu à esquerda da página, e em "<strong>Adicionar novo</strong>".`,
    },
    {
      id: 3,
      description: `Clique em "<strong>Escolher arquivo</strong>" e selecione o arquivo "<strong>wp-alright-plugin.zip</strong>" que você baixou no passo 1. Depois, clique em "<strong>Instalar agora</strong>". `,
    },
    {
      id: 4,
      description: `Em "<strong>Plugins</strong>" você encontrará o plugin "<strong>Alright Connector</strong>" instalado. Clique em "<strong>Ativar</strong>" e pronto! Agora você já pode ir para próxima etapa.`,
    },
  ],
  [
    {
      id: 1,
      description: `Ainda logado ao seu painel do WordPress, clique em <strong>Alright Connector</strong> na barra lateral esquerda da página.`,
    },
    {
      id: 2,
      description: `Copie o código "<strong>Tag Alright ID</strong>" abaixo, cole no campo indicado, e depois clique em "<strong>Salvar alterações" no painel do plugin.</strong>`,
    },
  ],
];

export default stepsListMock;
