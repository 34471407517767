import React from 'react';
import { Modal as ReactstrapModal } from 'reactstrap';
import {
  bool,
  func,
  string,
  node,
} from 'prop-types';

import Button from '../Button';
import * as S from './style';

export default function Modal({
  buttonText = '',
  children,
  hideFooter = false,
  isOpen = false,
  onClose = () => {},
  onConfirm = () => {},
  title = '',
  ...props
}) {
  return (
    <ReactstrapModal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onClose()}
      {...props}
    >
      <S.ModalHeader className="modal-header">
        <S.ModalTitle className="modal-title" id="modal-title-default">
          { title }
        </S.ModalTitle>
        <S.ModalClose
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onClose()}
        >
          <span aria-hidden>×</span>
        </S.ModalClose>
      </S.ModalHeader>
      <div className="modal-body">
        { children }
      </div>
      {!hideFooter
      && (
        <S.ModalFooter className="modal-footer">
          <Button
            color="primary"
            onClick={() => onConfirm()}
            outline
          >
            {buttonText}
          </Button>
        </S.ModalFooter>
      )}
    </ReactstrapModal>
  );
}

Modal.propTypes = {
  buttonText: string,
  children: node,
  hideFooter: bool,
  isOpen: bool,
  onClose: func,
  onConfirm: func,
  title: string,
};
