/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useNavigate } from '@reach/router';

import Button from '../../../../components/Button';
import Steps from '../../../../components/alright/Steps';
import ModalBlocks from '../../../../components/alright/ModalBlocks';
import { useInstallations } from '../../../../contexts/InstallationsContext';

import * as S from './style';
import { PAGES } from '../../../../constants';

export default function Form() {
  const [modalStatus, setModalStatus] = useState(false);
  const {
    manualSteps,
    publisherForm,
    postPublisherEtapa,
    setManualSteps,
  } = useInstallations();
  const navigate = useNavigate();

  useEffect(() => {
    setManualSteps(manualSteps, 0, 'success');
    setManualSteps(manualSteps, 1, 'success');
    setManualSteps(manualSteps, 2, 'current');
  }, [setManualSteps, manualSteps]);

  const openModalStatus = () => {
    setModalStatus(true);
  };

  const closeModalStatus = () => {
    setModalStatus(false);
    navigate(PAGES.BLOCOS.HOME());
  };

  const activateError = () => {
    setManualSteps(manualSteps, 2, 'error');
    closeModalStatus();
  };

  const confirmModal = () => {
    if (publisherForm) {
      setManualSteps(manualSteps, 2, 'success');
      navigate('/login');
    }

    activateError();
  };

  const changeStep = async () => {
    postPublisherEtapa()
    openModalStatus();
  };

  return (
    <>
      <Steps list={manualSteps} />
      <S.EmailIcon />
      <S.Title>
        Atenção, fique de olho no seu e-mail ;)
      </S.Title>
      <S.Text>
        <strong>
          Nos próximos dias você receberá um e-mail do Google&nbsp;
        </strong>
        com um questionário de preenchimento <strong>obrigatório&nbsp;</strong>
        para que possamos conectar seu site à nossa rede e começar a monetizá-lo.&nbsp;<br />
        Dentro desse mesmo período, caso haja algum problema entraremos em contato com você, não se preocupe.
      </S.Text>
      <S.ButtonLine>
        <Button
          color="primary"
          onClick={changeStep}
          outline
          size="lg"
        >
          <span>entendi, aguardarei o email</span>
        </Button>
      </S.ButtonLine>

      <ModalBlocks
        actionLink
        type="success"
        showModal={modalStatus}
        onConfirm={confirmModal}
        buttonText="Ver conteúdos"
        link="https://alright.com.br/blog/"
        buttonText2="Ir para Anúncios"
        onClose={closeModalStatus}
        title="Tudo certo até aqui, mas agora..."
        text="Enquanto você aguarda o seu acesso à plataforma estar disponível, conheça nossos conteúdos no nosso blog!"
      >
      </ModalBlocks>
    </>
  );
}
