import React from 'react';
import { string } from 'prop-types';

export default function Icon({ name = '', ...props }) {
  return (
    <span className="btn-inner--icon" {...props}>
      <i className={`fas fa-${name}`} />
    </span>
  );
}

Icon.propTypes = {
  name: string,
};
