import React from 'react';
import {
  func,
  string,
  bool,
  oneOf,
} from 'prop-types';

import * as S from './style';

export default function ModalAlert({
  buttonText = '',
  showModal = false,
  text = '',
  title = '',
  type = 'success',
  link = '',
  actionLink = false,
  onClose = () => {},
  onConfirm = () => {},
}) {
  return (
    <S.Wrapper
      show={showModal}
    >
      <S.Modal showCloseButton>
        <S.ButtonClose
          onClick={onClose}
        />
        {type === 'success'
          && <S.IconSuccess />}
        {type === 'warning'
          && <S.IconError />}
        <S.Title>
          {title}
        </S.Title>
        <S.Text>
          {text}
        </S.Text>
        { actionLink ? (
          <S.LinkButton href={link} target="_blank">
            {buttonText}
          </S.LinkButton>
        ) : (
          <S.ButtonComponent
            color="default"
            onClick={onConfirm}
            outline
          >
            {buttonText}
          </S.ButtonComponent>
        )}
      </S.Modal>
    </S.Wrapper>
  );
}

ModalAlert.propTypes = {
  link: string,
  onClose: func,
  onConfirm: func,
  showModal: bool,
  actionLink: bool,
  buttonText: string,
  text: string,
  title: string,
  type: oneOf([
    'success',
    'warning',
  ]),
};
