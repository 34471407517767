import { getDevelopmentToken } from '../../api/welcome.service';

async function generateDevelopementToken() {
  try {
    if (window.localStorage.getItem('token') === null) {
      const token = await getDevelopmentToken();

      if (token) {
        return {
          token,
          isValidToken: true,
        };
      }
    }

    return {};
  } catch (error) {
    return {
      error: true,
    };
  }
}

export default function authenticate() {
  return generateDevelopementToken();
}
