import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Loader } from '../../../assets/img/icons/component-loader.svg';

export default function ComponentLoader() {
  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
}

const LoaderWrapper = styled.div`
  width: 25px;
  height: 25px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
