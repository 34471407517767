export const APPLICATION = {
  DASH_URL: process.env.REACT_APP_DASH_URL,
  API_URL: process.env.REACT_APP_API_URL,
  ADMANAGER_URL: process.env.REACT_APP_API_URL_ADMANAGER,
};

export const URLREGEX = /^https?:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

function generatePath(path) {
  return `${process.env.PUBLIC_URL}${path !== '/' ? '/' : ''}${path}`;
}

export const ROUTES = {
  ROOT: generatePath('/'),
  TERMOS: generatePath('termos'),
  LOGIN: generatePath('login'),
  BLOCOS: {
    ROOT: generatePath('blocos'),
    MANUAL: {
      ROOT: 'manual',
      INSTALAR: 'instalar',
    },
  },
  CADASTRO: {
    ROOT: generatePath('cadastro'),
    ETAPA_1: 'etapa-1',
    ETAPA_1_CONCLUSAO: 'etapa-1-conclusao',
  },
  INSTALACOES: {
    ROOT: generatePath('instalacoes'),
    WORDPRESS: {
      ROOT: 'wordpress',
      INSTALAR: 'instalar',
      ATIVAR: 'ativar',
      FORMULARIO: 'formulario',
    },
    MANUAL: {
      ROOT: 'manual',
      INSTALAR: 'instalar',
      ATIVAR: 'ativar',
      FORMULARIO: 'formulario',
    },
  },
};

export const PAGES = {
  ROOT: () => ROUTES.ROOT,
  LOGIN: () => ROUTES.LOGIN,
  TERMOS: () => ROUTES.TERMOS,
  BLOCOS: {
    HOME: () => ROUTES.BLOCOS.ROOT,
    MANUAL_INSTALAR: () => `${ROUTES.BLOCOS.ROOT}/${ROUTES.BLOCOS.MANUAL.ROOT}/${ROUTES.BLOCOS.MANUAL.INSTALAR}`,
  },
  CADASTRO: {
    ETAPA_1: () => `${ROUTES.CADASTRO.ROOT}/${ROUTES.CADASTRO.ETAPA_1}`,
    ETAPA_1_CONCLUSAO: () => `${ROUTES.CADASTRO.ROOT}/${ROUTES.CADASTRO.ETAPA_1_CONCLUSAO}`,
  },
  INSTALACOES: {
    HOME: () => ROUTES.INSTALACOES.ROOT,
    WORDPRESS_INSTALAR: () => `${ROUTES.INSTALACOES.ROOT}/${ROUTES.INSTALACOES.WORDPRESS.ROOT}/${ROUTES.INSTALACOES.WORDPRESS.INSTALAR}`,
    WORDPRESS_ATIVAR: () => `${ROUTES.INSTALACOES.ROOT}/${ROUTES.INSTALACOES.WORDPRESS.ROOT}/${ROUTES.INSTALACOES.WORDPRESS.ATIVAR}`,
    WORDPRESS_FORMULARIO: () => `${ROUTES.INSTALACOES.ROOT}/${ROUTES.INSTALACOES.WORDPRESS.ROOT}/${ROUTES.INSTALACOES.WORDPRESS.FORMULARIO}`,
    MANUAL_INSTALAR: () => `${ROUTES.INSTALACOES.ROOT}/${ROUTES.INSTALACOES.MANUAL.ROOT}/${ROUTES.INSTALACOES.MANUAL.INSTALAR}`,
    MANUAL_ATIVAR: () => `${ROUTES.INSTALACOES.ROOT}/${ROUTES.INSTALACOES.MANUAL.ROOT}/${ROUTES.INSTALACOES.MANUAL.ATIVAR}`,
    MANUAL_FORMULARIO: () => `${ROUTES.INSTALACOES.ROOT}/${ROUTES.INSTALACOES.MANUAL.ROOT}/${ROUTES.INSTALACOES.MANUAL.FORMULARIO}`,
  },
};
