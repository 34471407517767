import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

import Icon from '../../../components/Icons';
import colors from '../../../components/GlobalStyle/colors';

export default function ErrorMessage({ title = '', description = '' }) {
  return (
    <MessageWrapper>
      <ErrorIcon>
        <Icon name="times" />
      </ErrorIcon>
      <Title>{title}</Title>
      <span>{description}</span>
    </MessageWrapper>
  );
}

ErrorMessage.propTypes = {
  title: string,
  description: string,
};

const MessageWrapper = styled.div`
  padding: 10px;
  font-size: 16px;
  margin: 30px 0 0;
  border-radius: 60px;
  background-color: #f1f1f1;
  color: ${colors.nightRider};
`;

const Title = styled.span`
  color: ${colors.red};
  font-weight: 700;
`;

const ErrorIcon = styled.div`
  width: 36px;
  height: 36px;
  font-size: 18px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  color: ${colors.white};
  justify-content: center;
  background-color: ${colors.red};
`;
