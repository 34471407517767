import ky from 'ky';

import { APPLICATION } from '../../constants';

export const request = ky.extend({
  prefixURL: APPLICATION.API_URL,
  retry: 3,
  hooks: {
    beforeRequest: [],
    afterResponse: [(_, __, response) => response],
  },
});

export const ajax = ky.extend({
  prefixURL: APPLICATION.API_URL,
  retry: 3,
  hooks: {
    beforeRequest: [(request, _) => {
      request.headers.set('X-Requested-With', 'XMLHttpRequest');
    }],
    afterResponse: [(_, __, response) => response],
  },
});