import React, { useState } from 'react';
import { useNavigate } from '@reach/router';
import { PAGES } from '../../../constants';
import IconNext from '../../../components/Icons';
import { postCheckApprovalUrl, postInventoryAdUnits } from '../../../api/installations.service';
import * as S from './style';


export default function HomeBlocks() {

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [modalError, setModalError] = useState(false);

  const goToManualInstallation = async  () => {
    const response =  await postCheckApprovalUrl(); 
    const approved = response.map( item => {
      const result = (item.approved)
      if (result === true){
        postInventoryAdUnits()
        navigate(PAGES.BLOCOS.MANUAL_INSTALAR());
      }else {
        setModalError(true)
      }
    });
  };
  const confirmModal = () => {
    navigate(PAGES.ROOT());
};
  return (
    <>
      { modalError && (
      <S.ModalAlertBlocks
        title="Agora é só aguardar! "
        text="Assim que o Google aprovar a conta, vamos ser avisados e iremos trabalhar na migração do nosso lado. E não se preocupe! Logo voltarei para te atualizar sobre a aprovação  e os próximos passos referente a instalação dos blocos de anúncio! "
        buttonText="Aguardar"
        type="warning"
        onClose={() => setOpen(false)}
        onConfirm={confirmModal}
        showModal={open}
      />
  )}
      <S.Disclaimer>
        Escolha a modalidade para seguir o passo a passo
      </S.Disclaimer>
      <S.Line>
        <S.CardOptions onClick={goToManualInstallation}>
          <S.CardTitle>
            {/* Meu site não é em Wordpress */}
            Realizar instalação
          </S.CardTitle>
          <S.TextCard>
            Siga o passo a passo e instale o necessário para
            sites que utilizam outras plataformas.
          </S.TextCard>
          <S.ButtonNext
            color="primary"
            outline
            className="btn-icon-only"
            icon
            onClick={goToManualInstallation}
          >
            <IconNext name="arrow-right" />
          </S.ButtonNext>
        </S.CardOptions>
      </S.Line>
    </>
  );
}
