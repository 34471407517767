import styled from 'styled-components';
import SyntaxHighlighter from 'react-syntax-highlighter';
import colors from '../../../../components/GlobalStyle/colors';

export const Disclaimer = styled.span`
  width: 100%;
  font-size: 16px;
  line-height: 1.5rem;
  margin: 5px 0 5px;
  display: inline-block;
  color: ${colors.black};
`;

export const h2 = styled.h2`
  padding: 0.1rem;
  text-align: center;
  margin-top: 0.5rem;
  color: ${colors.black};
`;

export const li = styled.li`
  margin-left: 1rem;
  color: ${colors.purple};
`;

export const Code = styled(SyntaxHighlighter)`
  border-radius: 10px;
  margin: 35px auto 0;
  white-space: pre-line;
  width: 100%;
  & code {
    word-break: break-word;
  }
`;

export const ButtonLine = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

export const DivContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding:1rem;
`;

export const Div = styled.div`
  display: block;

`;
export const Form = styled.form`
  display: flex;
  justify-content: center;
`;
export const Textarea = styled.textarea`
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: rgb(34, 34, 34) none repeat scroll 0% 0%;
  color: rgb(192, 192, 192);
  width: 15rem;
`;
export const DivSuccess = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  height: 1rem;
`;

export const DownloadLink = styled.a`
border: 2px ${colors.purple} solid;
border-radius: 50px;
color: ${colors.purple};
cursor: pointer;
font-size: 14px;
font-weight: 500;
margin-right: 15px;
padding: 0.625rem 1.25rem;
text-decoration: none;
transition: all 0.15s ease;

&:hover {
  background-color: ${colors.purple};
  border-color: ${colors.purple};
  color: ${colors.white} !important;
}
`;


