import styled from 'styled-components/macro';

import Badge from '../../../components/Badge';
import Button from '../../../components/Button';
import colors from '../../../components/GlobalStyle/colors';
import Card from '../../../components/Card';
import Icon from '../../../components/Icons';
import Input from '../../../components/Input';

export const Line = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Disclaimer = styled.span`
  color: ${colors.black};
  display: inline-block;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const CardOptions = styled(Card)`
  border: solid 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  height: 330px;
  padding-right: 40px;
  max-width: 290px;

  &:not(:first-child) {
    margin-left: 33px;
  }

  @media (max-width: 768px) {
    &:not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const CardTitle = styled.h2`
  color: ${colors.black};
  font-size: 22px;
  line-height: 1.07;
`;

export const TextCard = styled.p`
  color: ${colors.textBlack};
  font-size: 14px;
  line-height: 1.33;
`;

export const ButtonNext = styled(Button)`
  bottom: 25px;
  font-size: 20px;
  position: absolute;
  right: 25px;
`;

export const FormEmail = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 400px;
`;

export const InputEmail = styled(Input)`
  border-radius: 5px;
  border-color: ${colors.heavyGrey};

  &:focus {
    border-color: ${colors.purple}; 
  }
`;

export const InputError = styled.span`
  align-items: center;
  border-radius: 100%;
  color: ${colors.red};
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
`;

export const ErrorIcon = styled(Icon)`
  align-items: center;
  background-color: ${colors.red};
  border-radius: 100%;
  color: ${colors.white};
  display: flex;
  font-size: 8px;
  height: 16px;
  justify-content: center;
  margin-right: 8px;
  width: 16px;
`;

export const ErrorFeedback = styled(Badge)`
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 38px;
  color: ${colors.red};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-top: 16px;
  padding: 8px;
  text-transform: initial;
  white-space: unset;
`;

export const ButtonEmail = styled(Button)`
  margin: 42px auto 0;
`;

export const InstructionsButton = styled(Button)`
  position: absolute;
  bottom: 25px;
  right: 25px;
`;
