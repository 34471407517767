import React, { useState } from 'react';
import { useNavigate } from '@reach/router';
import { useFormik } from 'formik';

import { PAGES } from '../../../constants';
import Modal from '../../../components/Modal';
import IconNext from '../../../components/Icons';
import ModalAlert from '../../../components/alright/ModalAlert';
import { useInstallations } from '../../../contexts/InstallationsContext';

import * as S from './style';
import ComponentLoader from '../../../components/alright/ComponentLoader';

export default function Home() {
  const [modalEmail, setModalEmail] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const { postPublisherEmail } = useInstallations();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const goToManualInstallation = () => {
    navigate(PAGES.INSTALACOES.MANUAL_INSTALAR());
  };

  const openModalEmail = () => {
    setModalEmail(true);
  };

  const closeModalStatus = () => {
    setModalStatus(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      const response = await postPublisherEmail(values.email);

      if (response) {
        setSubmitting(false);
        setErrorEmail(false);
        setModalStatus(true);
        setModalEmail(false);
        setLoading(false);
        resetForm();
      } else {
        setLoading(false);
        setSubmitting(false);
        setErrorEmail(true);
      }
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Campo obrigatório';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = 'Email inválido';
      }
      return errors;
    },
  });

  const closeModalEmail = () => {
    setModalEmail(false);
    setErrorEmail(false);
    formik.setErrors({});
  };

  return (
    <>
      <S.Disclaimer>
        Escolha a modalidade para seguir o passo a passo
      </S.Disclaimer>
      <S.Line>
        {/* <S.CardOptions onClick={goToWordpressInstallation}>
          <S.CardTitle>
            Meu site é em Wordpress
          </S.CardTitle>
          <S.TextCard>
            Siga o passo a passo e instale nosso plugin
            para sites que utilizam a plataforma do Wordpress.
          </S.TextCard>
          <S.ButtonNext
            color="primary"
            outline
            className="btn-icon-only"
            icon
            onClick={goToWordpressInstallation}
          >
            <IconNext name="arrow-right" />
          </S.ButtonNext>
        </S.CardOptions> */}
        <S.CardOptions onClick={goToManualInstallation}>
          <S.CardTitle>
            {/* Meu site não é em Wordpress */}
            Realizar instalação
          </S.CardTitle>
          <S.TextCard>
            Siga o passo a passo e instale o necessário para
            sites que utilizam outras plataformas.
          </S.TextCard>
          <S.ButtonNext
            color="primary"
            outline
            className="btn-icon-only"
            icon
            onClick={goToManualInstallation}
          >
            <IconNext name="arrow-right" />
          </S.ButtonNext>
        </S.CardOptions>
        <S.CardOptions onClick={openModalEmail}>
          <S.CardTitle>
            Enviar instruções de instalação para alguém
          </S.CardTitle>
          <S.TextCard>
            Precisa de ajuda para instalar nossas tags em seu site? Você pode enviar elas
            para o email da pessoa que pode te ajudar com essa tarefa :)
          </S.TextCard>
          <S.InstructionsButton
            outline
            color="primary"
            onClick={openModalEmail}
          >
            Enviar instruções
          </S.InstructionsButton>
        </S.CardOptions>
      </S.Line>
      <Modal
        type="default"
        title="Enviar instruções de instalação por email"
        isOpen={modalEmail}
        onClose={closeModalEmail}
        hideFooter
      >
        <S.FormEmail onSubmit={formik.handleSubmit}>
          <S.InputEmail
            id="email"
            invalid={!!formik.errors.email || errorEmail}
            label="Email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="exemplo@alright.com.br"
            type="email"
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email
            && (
              <S.InputError>
                <S.ErrorIcon name="times" />
                {formik.errors.email}
              </S.InputError>
            )}
          {errorEmail
            && (
              <S.ErrorFeedback>
                <S.ErrorIcon name="times" />
                Ocorreu um erro! Tente novamente.
              </S.ErrorFeedback>
            )}
          <S.ButtonEmail
            color="primary"
            outline
            type="submit"
            disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
            onClick={() => setLoading(true)}
          >
            { !loading ? 'Enviar' : <ComponentLoader /> }
          </S.ButtonEmail>
        </S.FormEmail>
      </Modal>
      <ModalAlert
        title="Instruções enviadas com sucesso!"
        buttonText="Ok"
        onClose={closeModalStatus}
        onConfirm={closeModalStatus}
        showModal={modalStatus}
      />
    </>
  );
}
