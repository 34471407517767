/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from '@reach/router';

import Button from '../../../../components/Button';
import ModalAlert from '../../../../components/alright/ModalAlert';
import Steps from '../../../../components/alright/Steps';
import { useInstallations } from '../../../../contexts/InstallationsContext';
import { postEmailInstallationsDone } from '../../../../api/installations.service';

import * as S from './style';
import { PAGES } from '../../../../constants';
import { useGlobal } from '../../../../contexts/GlobalContext';

export default function Form() {
  const [modalStatus, setModalStatus] = useState(false);
  const {
    wordpressSteps,
    setWordpressSteps,
    publisherForm,
    publisherData,
  } = useInstallations();
  const { publisher, setRequestError } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    setWordpressSteps(wordpressSteps, 0, 'success');
    setWordpressSteps(wordpressSteps, 1, 'success');
    setWordpressSteps(wordpressSteps, 2, 'current');
  }, [setWordpressSteps, wordpressSteps]);

  const openModalStatus = () => {
    setModalStatus(true);
  };

  const closeModalStatus = () => {
    setModalStatus(false);
    navigate(PAGES.ROOT());
  };

  const activateError = () => {
    setWordpressSteps(wordpressSteps, 2, 'error');
    closeModalStatus();
  };

  const confirmModal = () => {
    if (publisherForm) {
      setWordpressSteps(wordpressSteps, 2, 'success');
      navigate('https://www.youtube.com/watch?v=Rribtgyn34w&list=PLrVrxxp6hWglVMc0zaYxcK-ZbAsKs09e2');
    }
    activateError();
  };

  const postFinalStepEmail = useCallback(async () => {
    try {
      await postEmailInstallationsDone(publisher.id);
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    }
  }, [publisher.id, setRequestError]);

  useEffect(() => {
    if (publisherData?.supertag && publisherData?.ads_txt) {
      postFinalStepEmail();
    }
  }, [postFinalStepEmail, publisherData]);

  const finishStep = async () => {
    openModalStatus();
  };

  return (
    <>
      <Steps list={wordpressSteps} />
      <S.EmailIcon />
      <S.Title>
        Atenção, fique de olho no seu e-mail ;)
      </S.Title>
      <S.Text>
        <strong>
          Nos próximos dias você receberá um e-mail do Google&nbsp;
        </strong>
        com um questionário de preenchimento <strong>obrigatório </strong>
        para que possamos conectar seu site à nossa rede e começar a monetizá-lo.&nbsp;<br />
        Dentro desse mesmo período, caso haja algum problema entraremos em contato com você, não se preocupe.
      </S.Text>
      <S.ButtonLine>
        <Button
          color="primary"
          onClick={finishStep}
          outline
          size="lg"
        >
          <span>entendi, aguardarei o email</span>
        </Button>
      </S.ButtonLine>
      <ModalAlert
        actionLink
        type="success"
        showModal={modalStatus}
        onConfirm={confirmModal}
        buttonText="Ver conteúdos"
        link="https://alright.com.br/blog/"
        onClose={closeModalStatus}
        title="Tudo certo até aqui, mas agora..."
        text="Enquanto você aguarda o seu acesso à plataforma estar disponível, conheça nossos conteúdos no nosso blog!"
      />
    </>
  );
}
