import React from 'react';
import { string, node } from 'prop-types';

import * as S from './style';

export default function Input({
  label = '',
  id = '',
  children,
  placeholder = '',
  type = 'text',
  ...props
}) {
  return (
    <>
      <label
        className="form-control-label"
        htmlFor={id}
      >
        {label}
      </label>
      <S.Input
        id={id}
        placeholder={placeholder}
        type={type}
        {...props}
      >
        {children}
      </S.Input>
    </>
  );
}

Input.propTypes = {
  label: string,
  id: string.isRequired,
  placeholder: string,
  type: string,
  children: node,
};
