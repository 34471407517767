import styled from 'styled-components/macro';

import colors from '../../../../components/GlobalStyle/colors';

export const Disclaimer = styled.span`
  color: ${colors.black};
  display: inline-block;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 30px;
  width: 100%;
`;

export const ButtonLine = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;
`;
