import React, { Suspense, useEffect } from 'react';
import styled from 'styled-components';
import {
  bool,
  element,
  func,
  object,
  oneOfType,
} from 'prop-types';

import { navigate } from '@reach/router';
import Loader from '../../../components/alright/Loader';
import { useGlobal } from '../../../contexts/GlobalContext';
import { hasUser, hasPublisher } from '../../../utils/helpers/helpers';
import { PAGES } from '../../../constants';

const WrapperLoaderLazyComponent = styled.div`
  margin: 10px 0;
`;

export default function LazyComponent({
  Component,
  Context,
  Element,
  isInstallationsRoute,
  isLoginRoute,
  isRegistrationRoute,
  isTermsRoute,
  isBlocksRoute,
  ...props
}) {
  const { state, isUserLogged } = useGlobal();

  useEffect(() => {
    if (isLoginRoute && isUserLogged) {
      navigate(PAGES.ROOT());
    }

    if (isInstallationsRoute && !isUserLogged) {
      navigate(PAGES.ROOT());
    }
    if (isBlocksRoute && !isUserLogged) {
      navigate(PAGES.ROOT());
    }

    if (isTermsRoute && !hasUser() && !hasPublisher()) {
      navigate(PAGES.ROOT());
    }

    if (isTermsRoute && isUserLogged && !state.isTermsConclusion) {
      navigate(PAGES.ROOT());
    }

    if (isRegistrationRoute && (isUserLogged || hasUser())) {
      navigate(PAGES.ROOT());
    }
  }, [state, isInstallationsRoute, isBlocksRoute, isLoginRoute, isRegistrationRoute, isTermsRoute, isUserLogged]);

  const content = () => {
    if (Component) {
      return <Component {...props} />;
    }

    if (Element) {
      return React.cloneElement(Element, props);
    }

    return null;
  };

  return (
    <Suspense fallback={(
      <WrapperLoaderLazyComponent>
        <Loader />
      </WrapperLoaderLazyComponent>
    )}
    >
      {Context ? (
        <Context>
          { content() }
        </Context>
      ) : content() }
    </Suspense>
  );
}

LazyComponent.defaultProps = {
  Component: null,
  Context: null,
  Element: null,
  isInstallationsRoute: false,
  isBlocksRoute: false,
  isLoginRoute: false,
  isRegistrationRoute: false,
  isTermsRoute: false,
};

LazyComponent.propTypes = {
  Component: oneOfType([func, object]),
  Context: oneOfType([func, element]),
  Element: oneOfType([func, element]),
  isInstallationsRoute: bool,
  isBlocksRoute: bool,
  isLoginRoute: bool,
  isRegistrationRoute: bool,
  isTermsRoute: bool,
};
