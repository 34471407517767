import React from 'react';
import {
  arrayOf,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types';

import Steps from './Step';
import * as S from './style';

export default function StepsBlocks({
  list,
  ...props
}) {
  return (
    <S.StepsContainer
      {...props}
    >
      {list.map((step) => (
        <Steps
          key={step.number}
          description={step.description}
          link={step.link}
          stepNumber={step.number}
          status={step.status}
        />
      ))}
    </S.StepsContainer>
  );
}

StepsBlocks.propTypes = {
  list: arrayOf(shape({
    description: string,
    link: string,
    number,
    status: oneOf([
      'current',
      'error',
      'pending',
      'success',
    ]),
  })),
};
