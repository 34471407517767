import styled from 'styled-components/macro';

import colors from '../../components/GlobalStyle/colors';

export const ModalHeader = styled.div`
  position: relative;
`;

export const ModalTitle = styled.h6`
  color: ${colors.black};
  font-size: 24px;
  margin: 0 auto;
  max-width: 240px;
  padding-top: 15px;
  text-align: center;
  width: 90%;
`;

export const ModalClose = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const ModalFooter = styled.div`
  justify-content: center;
`;
