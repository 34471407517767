import styled from 'styled-components';
import SyntaxHighlighter from 'react-syntax-highlighter';

import colors from '../../../../components/GlobalStyle/colors';
import Badge from '../../../../components/Badge';

export const Disclaimer = styled.span`
  color: ${colors.black};
  display: inline-block;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 30px;
  width: 100%;
`;

export const ButtonLine = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;
`;

export const DownloadLink = styled.a`
  border: 2px ${colors.purple} solid;
  border-radius: 50px;
  color: ${colors.purple};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
  padding: 0.625rem 1.25rem;
  text-decoration: none;
  transition: all 0.15s ease;

  &:hover {
    background-color: ${colors.purple};
    border-color: ${colors.purple};
    color: ${colors.white} !important;
  }
`;

export const Code = styled(SyntaxHighlighter)`
  border-radius: 10px;
  margin: 60px auto 0;
  white-space: pre-line;
  width: 100%;

  & code {
    word-break: break-word;
  }
`;

export const ErrorFeedback = styled(Badge)`
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 38px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  padding: 12px 20px;
  text-transform: initial;
  white-space: unset;
`;

export const ErrorIcon = styled.div`
  align-items: center;
  background-color: ${colors.red};
  display: flex;
  justify-content: center;
  border-radius: 100%;
  height: 45px;
  width: 45px;
`;

export const ErrorText = styled.p`
  color: ${colors.black};
  flex: 1;
  font-size: 16px;
  line-height: normal;
  margin: 0 0 0 20px;
  text-align: left;

  & span {
    color: ${colors.red};
    font-weight: 600;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
