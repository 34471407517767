import React, { Suspense, lazy } from 'react';
import { Router } from '@reach/router';

import Loader from './components/alright/Loader';
import LazyComponent from './components/alright/LazyComponent';
import { InstallationsProvider } from './contexts/InstallationsContext';
import { InstallationsProviderBlocks } from './contexts/installationsContextBlocks';
import InstallationsHome from './pages/Installations/Home';
import BlocksHome from './pages/Blocks/Home';
import BlocksManual, {
  Install as BlocksManualInstall,
} from './pages/Blocks/Manual';
import InstallationsWordpress, {
  Install as InstallationsWordpressInstall,
  Activate as InstallationsWordpressActivate,
  Form as InstallationsWordpressForm,
} from './pages/Installations/Wordpress';
import InstallationsManual, {
  Install as InstallationsManualInstall,
  Activate as InstallationsManualActivate,
  Form as InstallationsManualForm,
} from './pages/Installations/Manual';
import { ROUTES } from './constants';

const Login = lazy(() => import('./pages/Login'));
const Terms = lazy(() => import('./pages/Terms'));
const Blocks = lazy(() => import('./pages/Blocks'));
const Welcome = lazy(() => import('./pages/Welcome'));
const Registration = lazy(() => import('./pages/Registration'));
const Installations = lazy(() => import('./pages/Installations'));
const NotFound = lazy(() => import('./pages/NotFound'));

export default function Routes() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <LazyComponent
          Component={Welcome}
          path={ROUTES.ROOT}
        />
        <LazyComponent
          Component={Login}
          path={ROUTES.LOGIN}
          isLoginRoute
        />
        <LazyComponent
          Component={Registration}
          path={`${ROUTES.CADASTRO.ROOT}/*`}
        />
        <LazyComponent
          path={ROUTES.TERMOS}
          Component={Terms}
          isTermsRoute
        />
        <LazyComponent
          Component={Blocks}
          Context={InstallationsProviderBlocks}
          path={ROUTES.BLOCOS.ROOT}
          isBlockRoute
        >
          <BlocksHome path="/" />
          <BlocksManual path={`${ROUTES.BLOCOS.MANUAL.ROOT}`}>
            <BlocksManualInstall path={`${ROUTES.BLOCOS.MANUAL.INSTALAR}`} />
          </BlocksManual>
        </LazyComponent>

        <LazyComponent
          Component={Installations}
          Context={InstallationsProvider}
          path={ROUTES.INSTALACOES.ROOT}
          isInstallationsRoute
        >
          <InstallationsHome path="/" />
          <InstallationsWordpress path={`${ROUTES.INSTALACOES.WORDPRESS.ROOT}`}>
            <InstallationsWordpressInstall path={`${ROUTES.INSTALACOES.WORDPRESS.INSTALAR}`} />
            <InstallationsWordpressActivate path={`${ROUTES.INSTALACOES.WORDPRESS.ATIVAR}`} />
            <InstallationsWordpressForm path={`${ROUTES.INSTALACOES.WORDPRESS.FORMULARIO}`} />
          </InstallationsWordpress>
          <InstallationsManual path={`${ROUTES.INSTALACOES.MANUAL.ROOT}`}>
            <InstallationsManualInstall path={`${ROUTES.INSTALACOES.MANUAL.INSTALAR}`} />
            <InstallationsManualActivate path={`${ROUTES.INSTALACOES.MANUAL.ATIVAR}`} />
            <InstallationsManualForm path={`${ROUTES.INSTALACOES.MANUAL.FORMULARIO}`} />
          </InstallationsManual>
        </LazyComponent>
        <LazyComponent
          default
          path="404"
          Component={NotFound}
        />
      </Router>
    </Suspense>
  );
}
