/* eslint-disable quotes */

const stepsMock = [
  [
    {
      id: 1,
      description: `Copie o código da Tag Alright abaixo clicando no botão "<strong>Copiar tag</strong>".`,
    },
    {
      id: 2,
      description: `Cole o código dentro da tag <strong>&lt;/body&gt;</strong>, no <strong>index</strong> do seu site, e salve as alterações.`,
    },
    {
      id: 3,
      description: `Finalize clicando no botão "<strong>Confirmar instalação</strong>" abaixo, e você está pronto para a próxima etapa de adição do ads.txt.`,
    },
  ],
  [
    {
      id: 1,
      description: `Caso exista o arquivo "ads.txt" na raíz do seu site, copie o código abaixo e cole neste arquivo sem excluir as linhas previamente adicionadas. Se não existir esse arquivo, faça download clicando no botão abaixo, "<strong>Fazer download do arquivo.txt</strong>" e adicione na raíz do seu site.`,
    },
    {
      id: 2,
      description: `Finalize clicando no botão "<strong>Confirmar instalação</strong>" para ir para a próxima etapa de formulário do Google.`,
    },
  ],
];

export default stepsMock;
