import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { ReactComponent as IconLoader } from '../../../assets/img/icons/loader.svg';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px auto;
  transform: rotate(180deg);
`;

const dash = keyframes`
  to { stroke-dashoffset: -2; }
  to { stroke-dashoffset: 58; }
`;

const LoaderContent = styled.div`
  width: 100px;
  height: 100px;

  svg {
    transform-origin: 50% 50%;
    animation: rotate 20s linear infinite;

    polygon {
      stroke-dasharray: 15;
      stroke-width: 1;
      animation: ${dash} 1s ease-in-out infinite;
      stroke-dashoffset: -2;
    }
  }
`;

export default function Loader() {
  return (
    <LoaderWrapper>
      <LoaderContent>
        <IconLoader />
      </LoaderContent>
    </LoaderWrapper>
  );
}
