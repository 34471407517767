import styled from 'styled-components';
import SyntaxHighlighter from 'react-syntax-highlighter';

import colors from '../../../../components/GlobalStyle/colors';

export const Disclaimer = styled.span`
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 30px;
  display: inline-block;
  color: ${colors.black};
`;

export const ButtonLine = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
`;

export const Code = styled(SyntaxHighlighter)`
  width: 100%;
  margin: 0 auto;
  max-width: 220px;
  text-align: center;
  border-radius: 10px;
  white-space: pre-line;
`;

export const SupertagTitle = styled.div`
  margin: 0 auto;
  font-size: 16px;
  max-width: 175px;
  text-align: center;
  font-weight: normal;
  padding-bottom: 15px;
  color: ${colors.black};

  b {
    font-weight: 700;
    margin-left: 5px;
  }
`;
