import React from 'react';
import { hot } from 'react-hot-loader';

import Routes from '../../routes';
import Head from '../../components/Head';
import GlobalStyle from '../../components/GlobalStyle';

function App() {
  return (
    <>
      <Head
        titleTemplate="%s - "
        defaultTitle=""
      />
      <GlobalStyle />
      <main>
        <Routes />
      </main>
    </>
  );
}

export default hot(module)(App);
