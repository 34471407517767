import styled from 'styled-components';
import SyntaxHighlighter from 'react-syntax-highlighter';

import colors from '../../../../components/GlobalStyle/colors';

export const Disclaimer = styled.span`
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  margin: 30px 0 5px;
  display: inline-block;
  color: ${colors.black};
`;

export const Code = styled(SyntaxHighlighter)`
  border-radius: 10px;
  margin: 35px auto 0;
  white-space: pre-line;
  width: 100%;

  & code {
    word-break: break-word;
  }
`;

export const ButtonLine = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;
`;
